<template>
  <div>
    <div
      class="smp-draftPage"
      v-show="!loading">
      <template v-if="!memberLoginRequired">
        <router-link
          class="smp-draftPage-closeCta smp-button smp-button-primary"
          :to="{name: 'home'}">
          {{ $t('draftPage.closeCta') }}
          <fa-icon
            class="ml1"
            icon="times" />
        </router-link>

        <locale-menu v-if="showLocaleMenu" />

        <div class="smp-draftPage-postingWrapper">
          <posting-card
            v-if="posting"
            :posting="posting" />
        </div>

        <preview
          v-if="activePosting"
          :posting="activePosting"
          is-preview
          @close="$store.dispatch('closePreview')" />
      </template>

      <template v-if="memberLoginRequired">
        <p
          class="center"
          v-html="$t('draftPage.wrongUser.content')" />
        <button
          class="smp-button smp-button-primary"
          @click.prevent="switchAccount">
          <template v-if="autoLoginToken">
            {{ $t('draftPage.wrongUser.autoLogin') }}
          </template>
          <template v-else>
            {{ $t('draftPage.wrongUser.goToLogin') }}
          </template>
        </button>
      </template>
    </div>

    <div
      class="app--loading"
      v-if="loading">
      <fa-icon
        icon="spinner"
        spin />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { backendService } from '@/services/BackendService';
import PostingCard from '@/components/PostingCard.vue';
import LocaleMenu from '@/components/LocaleMenu.vue';
import Preview from '@/components/PostingPreview.vue';
import { logout } from '@/util/firebase';
import { getUrlParam, removeUrlParam } from '@/util/url';

export default {
  data() {
    return {
      posting: null,
      loading: true,
      memberLoginRequired: false,
      autoLoginToken: null,
    };
  },
  created() {
    this.init();
  },
  components: {
    PostingCard,
    LocaleMenu,
    Preview,
  },
  computed: {
    ...mapState(['cupProfile', 'activePosting']),
    ...mapGetters('user', ['isMember']),
    showLocaleMenu() {
      return this.cupProfile?.locales?.length > 1;
    },
  },
  methods: {
    async init() {
      this.autoLoginToken = getUrlParam('token');
      removeUrlParam('token');

      if (this.isMember) {
        this.posting = await backendService.getPreview(this.$route.params.id);
        this.loading = false;
      } else {
        this.memberLoginRequired = true;
        this.loading = false;
      }
    },
    async switchAccount() {
      try {
        if (this.autoLoginToken) {
          sessionStorage.setItem('smp-autoLoginToken', this.autoLoginToken);
        }

        sessionStorage.setItem('smp-loginBackUrl', location.href);
      } catch (e) {
        // noop, some browsers (e.g. some safari versions if in private mode) prevent session storage access
      }

      await logout();
    },
  },
};
</script>

<style lang="less">
@import (reference) "~@/styles/base";

.smp-draftPage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: @space-6 * 2;

  .smp-draftPage-closeCta {
    position: absolute;
    top: @space-2;
    right: @space-2;
  }

  .smp-draftPage-postingWrapper {
    .mt4;
    .flex-auto;
    width: 100%;

    .smp-postingCard {
      margin: 0 auto;
    }
  }
}
</style>
